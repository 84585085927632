import React from 'react'
import styled from 'styled-components'

const ImageThumbnailWrapper = styled.div`
    cursor: pointer;
    border: 1px solid ${props => props.isActive ? '#638FCB' : '#eeeeee' }; 
    border-radius: 5px;
    width: 100%;
    height: 100px;
    overflow: hidden;
`

const ThumbnailImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const ImageThumbnail = ({ isActive, onClick, image }) => {
    const handleClick = () => {
        onClick(image)
    }

    return (
        <ImageThumbnailWrapper onClick={handleClick} isActive={isActive}>
            <ThumbnailImage
                src={image.url}
                alt={image.altText || 'Product thumbnail'}
            />
        </ImageThumbnailWrapper>
    )
}

export default ImageThumbnail