import React from "react"
import ImageThumbnail from "./ImageThumbnail"
import styled from "styled-components"

const ImageGalleryWrapper = styled.section`
  > div:first-child {
    /* border: 1px solid #eee;
        border-radius: 5px; */
  }
  > div:last-child {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export default function ProductImageGallery({ selectedVariantImageId, images }) {
    // Add IDs to the images using their URLs
    const imagesWithIds = images.map(image => ({
        ...image,
        id: image.url // Use URL as the ID
    }));

    const [activeImageThumbnail, setActiveImageThumbnail] = React.useState(
        imagesWithIds[0] // Just use the first image as default
    );

    React.useEffect(() => {
        if (selectedVariantImageId) {
            const variantImage = imagesWithIds.find(img => img.url === selectedVariantImageId);
            if (variantImage) {
                setActiveImageThumbnail(variantImage);
            }
        }
    }, [selectedVariantImageId, imagesWithIds]);

    const handleClick = image => {
        setActiveImageThumbnail(image);
    }
    
    return (
        <>
        {activeImageThumbnail && (
            <ImageGalleryWrapper>
                <div>
                    <img 
                        src={activeImageThumbnail.url}
                        alt={activeImageThumbnail.altText || 'Product image'}
                        style={{ maxWidth: "400px" }}
                        className="d-block m-auto"
                    />
                </div>
                <div>
                    {imagesWithIds.map(image => (
                        <ImageThumbnail
                            key={image.url}
                            onClick={handleClick}
                            image={image}
                            isActive={activeImageThumbnail.url === image.url}
                        />
                    ))}
                </div>
            </ImageGalleryWrapper>
        )}
        </>
    )
}
