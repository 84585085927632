import React from 'react';
import { shopifyFetch } from '../utils/shopify';

import CartContext from '../context/CartContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle/PageTitle'
import {navigate, useLocation} from '@reach/router'
import queryString from 'query-string'

import { Button } from 'react-bootstrap'

import ProductImageGallery from '../components/ProductImageGallery/ProductImageGallery'
import ProductQuantityAdder from '../components/ProductQuantityAdder/ProductQuantityAdder'

import Img from 'gatsby-image'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import Cart from '../components/Cart/Cart'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft
    } from "@fortawesome/pro-solid-svg-icons"

import styled from 'styled-components'

const Grid = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;


    @media(min-width: 768px) {
        grid-template-columns: 30% auto;
        > div:first-child {
            order: 2;
        }
        > div:last-child {
            order: 1;
        }
    }
`;

const SelectWrapper = styled.div`
  margin-top: 40px;
  > strong {
    display: block;
    margin-bottom: 8px;
  }
`;

const Price = styled.div`
  margin: 40px 0;
  font-weight: bold;
  font-size: 30px;
`;



const ProductTemplate = ({ pageContext }) => {

  const { getProductById } = React.useContext(CartContext);
  const [product, setProduct] = React.useState(null);
  const [selectedVariant, setSelectedVariant] = React.useState(null);
  const {search, origin, pathname} = useLocation();
  const variantId = queryString.parse(search).variant;
  const { shopifyId } = pageContext;
  

  React.useEffect(() => {
    const getProduct = async () => {
      console.log('Fetching product with ID:', shopifyId); // Debug log

      try {
        const response = await shopifyFetch({
          query: `
            {
    product(id: "${shopifyId}") {
      id
      shopifyId: id
      title
      descriptionHtml
      handle
      images(first: 20) {
        edges {
          node {
            url
            altText
          }
        }
      }
      variants(first: 100) {
        edges {
          node {
            id
            availableForSale
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
            selectedOptions {
              name
              value
            }
            image {
              url
              altText
            }
          }
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      options(first: 10) {
        name
        values
      }
    }
  }
          `,
          //variables: { id: shopifyId },
        });

        console.log('API Response:', response);

        if (response.body?.data?.product) {
          setProduct(response.body.data.product);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (shopifyId) {
      getProduct();
    }
  }, [shopifyId]);

  React.useEffect(() => {
    // Set initial variant when product loads
    if (product?.variants?.edges?.length > 0) {
      setSelectedVariant(product.variants.edges[0].node);
    }
  }, [product]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleVariantChange = (e) => {
    const newVariant = product.variants.edges.find(
      edge => edge.node.id === e.target.value
    )?.node;
    setSelectedVariant(newVariant);
  };

  const price = selectedVariant?.price?.amount || product.priceRange.minVariantPrice.amount;
  const images = product.images.edges.map(edge => edge.node);

  return (
    <Layout>
      <SEO title={product.title} />
      <PageTitle pageTitle={product.title} link="/" />
      
      <ContentBlock>
        <Button className="ussIdahoButton" onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: '5px' }}
            aria-hidden="true"
          />
          Back to products
        </Button>
        
        <Grid>
          <div style={{ marginTop: '1rem' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
              <div>
                <h2>{product.title}</h2>
              </div>
              <div>
                <Cart />
              </div>
            </div>

            {product.variants.edges.length > 1 && (
              <SelectWrapper>
                <strong>Variant</strong>
                <select 
                  value={selectedVariant?.id || ''}
                  onChange={handleVariantChange}
                >
                  {product.variants.edges.map(({ node }) => (
                    <option key={node.id} value={node.id}>
                      {node.selectedOptions.map(opt => opt.value).join(' / ')}
                    </option>
                  ))}
                </select>
              </SelectWrapper>
            )}

            <Price>
              ${parseFloat(price).toFixed(2)}
            </Price>

            {selectedVariant && (
              <ProductQuantityAdder
                variantId={selectedVariant.id}
                available={selectedVariant.availableForSale}
              />
            )}

            <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
          </div>

          <div style={{ marginTop: '1rem' }}>
            <ProductImageGallery
              images={images}
              selectedVariantImageId={selectedVariant?.image?.id}
            />
          </div>
        </Grid>
      </ContentBlock>
    </Layout>
  );
};

export default ProductTemplate;
